import { Route } from 'wouter';
import { Box, ThemeProvider, CssBaseline, useMediaQuery } from '@mui/material';
import { ColorModeContext, useMode } from './theme';
import MySidebar from './components/sidebar';
import Dashboard from './components/dashboard';
import Greenhouse from './components/greenhouse';
import { useProSidebar } from 'react-pro-sidebar';
import Login from './components/login';
import Header from './components/header';
import { useIsAuthenticated } from 'react-auth-kit';

function App() {
  const [theme, colorMode] = useMode();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const { collapsed } = useProSidebar();
  const isAuthenticated = useIsAuthenticated();

  const sideBarLarge = {
    gridTemplateColumns: '270px calc(100% - 270px)',
    gridTemplateRows: 'repeat(1, 1fr)',
    gridTemplateAreas: `
      "a b"
    `,
  };

  const sideBarSmall = {
    gridTemplateColumns: '85px calc(100% - 85px)',
    gridTemplateRows: 'repeat(1, 1fr)',
    gridTemplateAreas: `
      "a b"
    `,
  };

  if (!isNonMobile) {
    sideBarLarge.gridTemplateColumns = sideBarSmall.gridTemplateColumns;
    sideBarLarge.gridTemplateRows = sideBarSmall.gridTemplateRows;
    sideBarLarge.gridTemplateAreas = sideBarSmall.gridTemplateAreas;
  }

  if (!isAuthenticated()) {
    sideBarLarge.gridTemplateColumns = '100%';
    sideBarLarge.gridTemplateRows = '100%';
    sideBarLarge.gridTemplateAreas = `
      "b"
    `;
    sideBarSmall.gridTemplateColumns = '100%';
    sideBarSmall.gridTemplateRows = '100%';
    sideBarSmall.gridTemplateAreas = `
      "b"
    `;
  }


  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          display="grid"
          width="100%"
          height="100%"
          sx={collapsed ? sideBarSmall : sideBarLarge}
        >
          <Header gridArea="a" />
          <MySidebar gridArea="a" />
          <Box
            gridArea="b"
          >
            <Route path='/' component={Dashboard} />
            <Route path='/greenhouse1' component={Greenhouse} />
            <Route path='/greenhouse2' component={Greenhouse} />
            <Route path='/greenhouse3' component={Greenhouse} />
            <Route path='/login' component={Login} />
          </Box>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;