import React, { useState, useEffect } from 'react';
import GenerateChart from './chart';
import { useTheme, Box, Typography } from '@mui/material';
import { tokens } from '../theme';
import mqtt from 'mqtt/dist/mqtt';

const mqtt_server = "ws://mqtt.toytronics.com:8883/mqtt";
const mqtt_username = "maha256";
const mqtt_password = "0j&39ZPcxY8icKie";

let subscribed = false;
const mqtt_options = {
    username: mqtt_username,
    password: mqtt_password,
};

function Greenhouse() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const re = /[^0-9](?=[0-9])/g;
    let tempRead = false;
    let moistRead = false;
    let lightRead = false;
    let fetchUrl = "";
    let humiditySub = "";
    let temperatureSub = "";
    let luxSub = "";
    let isWide = false;

    if (window.innerWidth > window.innerHeight) {
        isWide = true;
    } else {
        isWide = false;
    }

    let tempSeriesArray = [];
    let moistSeriesArray = [];
    let lightSeriesArray = [];
    let timeSeriesArray = [];
    const [tempSeries, setTempSeries] = useState([0]);
    const [moistSeries, setMoistSeries] = useState([0]);
    const [lightSeries, setLightSeries] = useState([0]);
    const [timeSeries, setTimeSeries] = useState(['']);

    switch (window.location.pathname) {
        case "/greenhouse1":
            fetchUrl = "https://greenhouse-api.onrender.com/greenhouseone";
            humiditySub = "Bearform/GreenhouseOne/Humidity";
            temperatureSub = "Bearform/GreenhouseOne/Temperature";
            luxSub = "Bearform/GreenhouseOne/Lux";
            break;
        case "/greenhouse2":
            fetchUrl = "https://greenhouse-api.onrender.com/greenhousetwo";
            humiditySub = "Bearform/GreenhouseTwo/Humidity";
            temperatureSub = "Bearform/GreenhouseTwo/Temperature";
            luxSub = "Bearform/GreenhouseTwo/Lux";
            break;
        case "/greenhouse3":
            fetchUrl = "https://greenhouse-api.onrender.com/greenhousethree";
            humiditySub = "Bearform/GreenhouseThree/Humidity";
            temperatureSub = "Bearform/GreenhouseThree/Temperature";
            luxSub = "Bearform/GreenhouseThree/Lux";
            break;
        default:
            fetchUrl = "https://greenhouse-api.onrender.com/greenhouseone";
            humiditySub = "Bearform/GreenhouseOne/Humidity";
            temperatureSub = "Bearform/GreenhouseOne/Temperature";
            luxSub = "Bearform/GreenhouseOne/Lux";
            break;
    }

    const handleReadings = (readings) => {
        tempSeriesArray = [];
        moistSeriesArray = [];
        lightSeriesArray = [];
        timeSeriesArray = [];

        for (var i = 0; i < readings.length; i++) {
            tempSeriesArray.push(readings[i].reading[1]);
            moistSeriesArray.push(readings[i].reading[0]);
            lightSeriesArray.push(readings[i].reading[2]);
            timeSeriesArray.push(readings[i].modifiedDate);
        }

        setTempSeries(tempSeriesArray);
        setMoistSeries(moistSeriesArray);
        setLightSeries(lightSeriesArray);
        setTimeSeries(timeSeriesArray);
    };

    useEffect(() => {
        fetch(fetchUrl)
            .then((response) => response.json())
            .then((data) => {
                handleReadings(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    // useEffect(() => {
    //     const client = mqtt.connect(mqtt_server, mqtt_options);
    //     client.on("connect", () => {
    //         if (!subscribed) {
    //             subscribed = true;
    //             console.log("Connected to MQTT Broker");
    //             client.subscribe(humiditySub);
    //             client.subscribe(temperatureSub);
    //             client.subscribe(luxSub);
    //             client.on("message", (topic, payload) => {
    //                 handleMessage(topic, payload);
    //             });
    //         }
    //     });
    // }, []);

    const handleMessage = (topic, payload) => {
        console.log("Received Message: " + topic + " Payload: " + payload);
        console.log(tempSeriesArray.length);

        switch (topic) {
            case temperatureSub:
                tempRead = true;
                break;
            case humiditySub:
                moistRead = true;
                break;
            case luxSub:
                lightRead = true;
                break;
        }

        if (tempRead && moistRead && lightRead) {
            tempRead = false;
            moistRead = false;
            lightRead = false;
            fetch(fetchUrl)
                .then((response) => response.json())
                .then((data) => {
                    handleReadings(data);
                    console.log("Data Updated");
                    console.log(window.location.pathname);
                })
                .catch((err) => {
                    console.log(err.message);
                });
        }
    };

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: isWide ? '70%' : '100%',
                gridTemplateAreas: `
                    "b"
                    "b"
                    "b"
                    "b"
                `,
                gap: 3,
                padding: 0,
                margin: 0,
                justifyContent: 'center',
            }}

        >
            <Typography
                gridArea="b"
                variant="h1"
                style={{
                    textAlign: 'center',
                    color: theme.palette.secondary[100],
                }}
            >{window.location.pathname.substring(1).charAt(0).toUpperCase() + window.location.pathname.substring(2).replace(re, '$& ')}
            </Typography>
            <GenerateChart gridArea="b" title="Temperature" yaxis="°C" series={[{ name: "Temperature", data: tempSeries }]} categories={timeSeries} />

            <GenerateChart gridArea="b" title="Humidity" yaxis="%" series={[{ name: "Humidity", data: moistSeries }]} categories={timeSeries} />

            <GenerateChart gridArea="b" title="Lux" yaxis="lx" series={[{ name: "Light", data: lightSeries }]} categories={timeSeries} />
        </Box>
    );
}

export default Greenhouse;