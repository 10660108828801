import { Link } from 'wouter';
import { Box, useMediaQuery } from '@mui/material';
import React from 'react';
import GreenhouseSummary from "./greenhouseSummery";
import StyledBox from './styledBox';

const gridTemplateLargeScreens = `
    ". . . . ."
    "a a a a a"
    "b b b b b"
    "c c c c c"
    ". . . . ."
`;

const gridTemplateSmallScreens = `
    "a"
    "a"
    "b"
    "b"
    "c"
    "c"
`;

function Dashboard(props) {
    const isAboveMediumScreens = useMediaQuery('(min-width: 960px)');
    return (
        <Box
            width="100%"
            height="100%"
            display="grid"
            gap="1.5rem"
            sx={isAboveMediumScreens
                ?
                {
                    gridTemplateColumns: '10% repeat(3, 1fr) 10%',
                    gridTemplateRows: '10% repeat(3, 1fr) 10%',
                    gridTemplateAreas: gridTemplateLargeScreens,
                }
                : {
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    gridTemplateRows: 'repeat(12, 1fr)',
                    gridTemplateAreas: gridTemplateSmallScreens,
                }}
        >
            <Box gridArea="a">
                <Link href='/greenhouse1'>
                    <GreenhouseSummary greenhouse="Greenhouse 1" path="greenhouseone" />
                </Link>
            </Box>
            <Box gridArea="b">
                <Link href='/greenhouse2'>
                    <GreenhouseSummary greenhouse="Greenhouse 2" path="greenhousetwo" />
                </Link>
            </Box>
            <Box gridArea="c">
                <Link href='/greenhouse3'>
                    <GreenhouseSummary greenhouse="Greenhouse 3" path="greenhousethree" />
                </Link>
            </Box>
        </Box>
    );
}

export default Dashboard;