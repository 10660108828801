import React from 'react';
import Chart from 'react-apexcharts';
import { useTheme, Box, useMediaQuery } from '@mui/material';
import { tokens } from '../theme';
import StyledBox from './styledBox';

function GenerateChart(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const datetimed = [];

    var isWide = true;


    if (window.innerWidth > window.innerHeight) {
        isWide = true;
    } else {
        isWide = false;
    }

    for (let i = 0; i < props.categories.length; i++) {
        datetimed.push(new Date(props.categories[i]).getTime());
        datetimed[i] += 7200000;
    }

    var options = {
        chart: {
            type: "area",
            style: {
                fontFamily: "Source Sans Pro",
                color: colors.grey[100],
                background: colors.primary[400],
            },
            toolbar: {
                fill: colors.primary[400],
                style: {
                    background: colors.primary[400],
                    colors: colors.grey[100],
                },
            },
        },

        dataLabels: {
            enabled: false
        },
        series: props.series,
        title: {
            text: props.title,
            style: {
                color: colors.grey[100],
            }
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
                colors: colors.blueAccent[900],
            }
        },
        tooltip: {
            x: {
                show: true,
                format: "hh:mm:ss",
            },
            theme: theme.palette.mode,
            style: {
                background: colors.primary[400],
                color: colors.grey[100],
            },
        },
        xaxis: {
            type: "datetime",
            categories: datetimed,
            labels: {
                style: {
                    colors: colors.grey[100],
                }
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: colors.grey[100],
                }
            },
            title: {
                text: props.yaxis,
                style: {
                    color: colors.grey[100],
                },
            },
        },
    };

    return (
        <StyledBox
            sx={{
            }}
        >
            <Chart options={options} series={options.series} type="area" />
        </StyledBox>

    );
}

export default GenerateChart;