import { useState } from "react";
import { useSignIn, useIsAuthenticated } from "react-auth-kit";
import { Box, Typography, TextField, Button, FormControl, Alert } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import LoginIcon from '@mui/icons-material/Login';
import StyledBox from "./styledBox";
import axios, { AxiosError } from "axios";
import { useFormik } from "formik";
import { useTheme } from "@mui/material/styles";
import { tokens } from "../theme";
import qs from "qs";
import { useLocation } from "wouter";


function Login(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const signIn = useSignIn();
    const isAuthenticated = useIsAuthenticated();
    const [error, setError] = useState("");
    const [location, setLocation] = useLocation();

    const handleSubmit = async (values) => {
        console.log("Values: ", values);
        setError("");

        const url = "https://greenhouse-api.onrender.com/loginauth";
        const data = { 'user': values.user, 'password': values.password };

        const options = {
            method: "POST",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            data: qs.stringify(data),
            url,
        };

        console.log("Options: ", options);

        try {
            const response = await axios(options);

            console.log("Response: ", response);

            signIn({
                token: response.data.token,
                expiresIn: 60 * 60 * 24 * 14,
                tokenType: "Bearer",
                authState: { user: values.user },
            })

            console.log("Signed in");

        } catch (error) {
            if (error & error instanceof AxiosError) {
                setError(error.response?.data.message);
            } else if (error && error instanceof Error) {
                setError(error.message);
            }
        }
    };

    if (isAuthenticated()) {
        setLocation("/");
    }

    const formik = useFormik({
        initialValues: {
            user: "",
            password: "",
        },
        onSubmit: handleSubmit,
    });

    return (
        <StyledBox
            height='100%'
            width='100%'
            display='grid'
            sx={{
                gridTemplateColumns: '1fr 300px 1fr',
                gridTemplateRows: '1fr 400px 1fr',
                gridTemplateAreas: `
                "a e c"
                "a b c"
                "a v c"
            `,
            }}
        >
            <StyledBox
                gridArea='b'
                display='grid'
                sx={{
                    gridTemplateColumns: '2% 96% 2%',
                    gridTemplateRows: '1fr 3fr',
                    gridTemplateAreas: `
                    "d a e"
                    "g b f"
                `,
                    alignItems: "center",
                    justifyItems: "center",
                }}
            >
                <Box gridArea='a' textAlign='center'>
                    <Typography variant='h4'>Greenhouse Monitor</Typography>
                    <Typography variant='h4'>Login</Typography>
                </Box>
                <Box
                    gridArea='b'
                    component='form'
                    onSubmit={formik.handleSubmit}
                    noValidate
                    sx={{ mt: 1 }}>
                    <FormControl fullWidth>
                        <Alert severity='error' sx={{ display: error ? "block" : "none" }}>
                            Incorrect user or password
                        </Alert>



                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            id='email'
                            label='User Name'
                            name='user'
                            autoComplete='user'
                            autoFocus
                            {...formik.getFieldProps("user")}
                        />
                        <TextField
                            margin='normal'
                            required
                            fullWidth
                            name='password'
                            label='Password'
                            type='password'
                            id='password'
                            autoComplete='current-password'
                            {...formik.getFieldProps("password")}
                        />
                        <LoadingButton
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            sx={{ mt: 3, mb: 2 }}
                            loading={formik.isSubmitting}
                            endIcon={<LoginIcon />}
                        >
                            Sign In
                        </LoadingButton>

                        <Box
                            m={1}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                        </Box>
                    </FormControl>
                </Box>
            </StyledBox>
        </StyledBox>
    );
}


export default Login; 