import React, { useState, useEffect } from 'react';
import { useTheme, Box, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { tokens } from '../theme';
import mqtt from 'mqtt/dist/mqtt';


const mqtt_server = "ws://mqtt.toytronics.com:8883/mqtt";
const mqtt_username = "maha256";
const mqtt_password = "0j&39ZPcxY8icKie";

const gridArea = `
    "e e e"
    "a a a"
    "a a a"
    "b c d"
    "b c d"
`;

let subscribed = false;
const mqtt_options = {
    username: mqtt_username,
    password: mqtt_password,
};

const DashboardBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.light,
    borderRadius: "1rem",
    boxShadow: "0.15rem 0.2rem 0.15rem 0.1rem rgba(0, 0, 0, .8)",
}));

function GreenhouseSummary(props) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    let readingSeriesArray = [];
    const [reading, setReading] = useState([0]);
    const [tempColor, setTempColor] = useState("");
    const [tempText, setTempText] = useState("");
    const [moistColor, setMoistColor] = useState("");
    const [moistText, setMoistText] = useState("");
    const [lightColor, setLightColor] = useState("");
    const [lightText, setLightText] = useState("");
    const isNonMobile = useMediaQuery("(min-width: 600px)");

    const tempSx = {
        textAlign: "center",

        gridTemplateColumns: 'repeat(1, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
        gridTemplateAreas: `
        "a"
        "b"
        `,
        backgroundColor: tempColor,
        color: tempText,
        borderRadius: isNonMobile ? "1rem" : "0.5rem",
        width: isNonMobile ? "90%" : "100%",
    };
    const moistSx = {
        textAlign: "center",
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
        gridTemplateAreas: `
        "a"
        "b"
        `,
        backgroundColor: moistColor,
        color: moistText,
        borderRadius: isNonMobile ? "1rem" : "0.5rem",
        width: isNonMobile ? "90%" : "100%",
    };
    const lightSx = {
        textAlign: "center",
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
        gridTemplateAreas: `
        "a"
        "b"
        `,
        backgroundColor: lightColor,
        color: lightText,
        borderRadius: isNonMobile ? "1rem" : "0.5rem",
        width: isNonMobile ? "90%" : "100%",
    };

    const handleReading = (input) => {

        readingSeriesArray = [];
        readingSeriesArray.push(input.reading[1]);
        readingSeriesArray.push(input.reading[0]);
        readingSeriesArray.push(input.reading[2]);

        updateMoist(input.reading[0]);
        updateTemp(input.reading[1]);
        updateLight(input.reading[2]);

        setReading(readingSeriesArray);
    };

    const updateMoist = (input) => {
        if (70 < input && input < 95) {
            setMoistColor(theme.palette.reading.green);
            setMoistText(theme.palette.primary.main);
        } else if (input < 60 && input < 95) {
            setMoistColor(theme.palette.reading.yellow);
            setMoistText(theme.palette.primary.main);
        } else if (input < 40 && 95 < input) {
            setMoistColor(theme.palette.reading.red);
            setMoistText(theme.palette.main);
        }
    };
    const updateTemp = (input) => {
        if (22 <= input && input <= 30) {
            setTempColor(theme.palette.reading.green);
            setTempText(theme.palette.primary.main);
        }
        else if (input < 15 && input > 32) {
            setTempColor(theme.palette.reading.red);
            setTempText(theme.palette.primary.main);
        }
        else {
            setTempColor(theme.palette.reading.yellow);
            setTempText(theme.palette.primary.main);
        }
    };

    const updateLight = (input) => {
        if (input < 70000 && input > 500) {
            setLightColor(theme.palette.reading.green);
            setLightText(theme.palette.primary.main);
        }
        else if (1000 < input && input < 5000) {
            setLightColor(theme.palette.reading.green);
            setLightText(theme.palette.primary.main);
        }
        else if (input > 70000) {
            setLightColor(theme.palette.reading.green);
            setLightText(theme.palette.primary.main);
        }
    };

    useEffect(() => {
        fetch("https://greenhouse-api.onrender.com/" + props.path + "/last")
            .then((response) => response.json())
            .then((data) => {
                handleReading(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    // useEffect(() => {
    //     const client = mqtt.connect(mqtt_server, mqtt_options);
    //     client.on("connect", () => {
    //         if (!subscribed) {
    //             subscribed = true;
    //             console.log("Connected to MQTT Broker");
    //             client.subscribe("Bearform/GreenhouseOne/Humidity");
    //             client.subscribe("Bearform/GreenhouseOne/Temperature");
    //             client.subscribe("Bearform/GreenhouseOne/Lux");
    //             client.on("message", (topic, payload) => {
    //                 handleMessage(topic, payload);
    //             });
    //         }
    //     });
    // }, []);

    const handleMessage = (topic, payload) => {
        fetch("https://greenhouse-api.onrender.com/greenhouseone/last")
            .then((response) => response.json())
            .then((data) => {
                handleReading(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };

    {/* <div onClick={props.onClick} className="summary" style={{ backgroundColor: colors.greenAccent[600], borderColor: `${colors.primary[100]}` }}>
            </div> */}

    if (reading.length > 1) {
        return (
            <DashboardBox
                display="grid"
                onClick={props.onClick}
                sx={{
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridTemplateRows: 'repeat(6, 1fr)',
                    gridTemplateAreas: gridArea,
                    gridGap: '1rem',
                    alignItems: "center",
                    justifyItems: "center",
                }}
            >
                <Box gridArea="a">
                    <Typography variant="h2" sx={{ textAlign: "center", color: theme.palette.secondary[100] }}>
                        {props.greenhouse}
                    </Typography>
                </Box>
                <Box
                    gridArea="b"
                    display="grid"
                    sx={tempSx}
                >
                    <Typography gridArea="a" variant="h4" sx={{ color: theme.palette.secondary[100] }}>
                        Temperature:
                    </Typography>
                    <Typography gridArea="b" variant="h4" sx={{ color: theme.palette.secondary[100] }}>
                        {reading[0]} °C
                    </Typography>
                </Box>
                <Box
                    gridArea="c"
                    display="grid"
                    sx={moistSx}
                >
                    <Typography gridArea="a" variant="h4" sx={{ color: theme.palette.secondary[100] }}>
                        Humidity:
                    </Typography>
                    <Typography gridArea="b" variant="h4" sx={{ color: theme.palette.secondary[100] }}>
                        {reading[1]} %
                    </Typography>
                </Box>
                <Box
                    gridArea="d"
                    display="grid"
                    sx={lightSx}
                >
                    <Typography gridArea="a" variant="h4" sx={{ color: theme.palette.secondary[100] }}>
                        Lux:
                    </Typography>
                    <Typography gridArea="b" variant="h4" sx={{ color: theme.palette.secondary[100] }}>
                        {reading[2]} lx
                    </Typography>
                </Box>
            </DashboardBox>

        );
    }
}

export default GreenhouseSummary;