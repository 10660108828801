import React, { useContext } from "react";
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar, sidebarClasses, SubMenuFR } from "react-pro-sidebar";
import { Link } from "wouter";
import { useTheme, Box, IconButton, Button } from "@mui/material";
import { ColorModeContext, tokens } from "../theme";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useIsAuthenticated, useSignOut } from "react-auth-kit";


function MySidebar() {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
        useProSidebar();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const signOut = useSignOut();
    const isAuthenticated = useIsAuthenticated();

    if (!isAuthenticated()) {
        return (
            <></>
        );
    } else {
        return (<Box sx={{ position: 'fixed', display: 'flex', height: '100vh', margin: '0', opacity: '100%', zIndex: '1' }}>
            <Sidebar
                backgroundColor={colors.primary[400]}
                rootStyles={{
                    [`.${sidebarClasses.container}`]: {
                        opacity: '100%',
                        backgroundColor: colors.primary[400],
                        height: '100%',
                    },
                }}
            >
                <Menu>
                    <MenuItem
                        icon={<MenuOutlinedIcon />}
                        onClick={() => { collapseSidebar(); }}
                        style={{
                            margin: "10px 0 10px 0",
                            color: colors.grey[100],
                        }}
                    >
                        <b>Greenhouse Monitor</b>
                    </MenuItem>
                    <MenuItem
                        icon={<HomeOutlinedIcon />}
                        component={<Link href="/" />}
                        style={{
                            margin: "10px 0 10px 0",
                            color: colors.grey[100],
                        }}
                    >
                        Home
                    </MenuItem>
                    <MenuItem
                        icon={<OnDeviceTrainingIcon />}
                        component={<Link href="/greenhouse1" />}
                        style={{
                            color: colors.grey[100],
                        }}
                    >
                        Greenhouse 1
                    </MenuItem>
                    <MenuItem
                        icon={<OnDeviceTrainingIcon />}
                        component={<Link href="/greenhouse2" />}
                        style={{
                            color: colors.grey[100],
                        }}
                    >
                        Greenhouse 2
                    </MenuItem>
                    <MenuItem
                        icon={<OnDeviceTrainingIcon />}
                        component={<Link href="/greenhouse3" />}
                        style={{
                            color: colors.grey[100],
                        }}
                    >
                        Greenhouse 3
                    </MenuItem>

                    <MenuItem
                        style={{
                            backgroundColor: colors.primary[400],
                        }}
                    ><Box display="flex" >
                            <IconButton onClick={colorMode.toggleColorMode}>
                                {theme.palette.mode === "dark" ? (
                                    <DarkModeOutlinedIcon />
                                ) : (
                                    <LightModeOutlinedIcon />
                                )}
                            </IconButton>
                        </Box>
                    </MenuItem>

                </Menu>
                <Menu
                    id="footer"
                    style={{
                        width: '100%',
                        alignSelf: 'flex-end',
                        position: 'absolute',
                        bottom: '0',
                    }}
                >
                    <MenuItem
                        rootStyles={{
                            [`.${sidebarClasses.container}`]: {
                                opacity: '100%',
                                backgroundColor: colors.primary[400],
                                bottom: '0%',
                            },
                        }}

                        style={{
                            display: 'flex',
                            position: 'relative',
                            bottom: '0',
                            alignItems: 'center',
                            paddingBottom: '20px',
                            backgroundColor: colors.primary[400],
                        }}
                    >
                        <Button
                            variant="outlined"
                            startIcon={<LogoutIcon />}
                            style={{
                                alignSelf: 'center',
                                color: colors.grey[100],
                            }}
                            onClick={signOut}
                        >
                            Logout
                        </Button>
                    </MenuItem>
                </Menu>
            </Sidebar>
        </Box>
        );
    }

    return (<></>);
}

export default MySidebar;