import { useIsAuthenticated } from "react-auth-kit";
import { useLocation } from "wouter";

function Header() {
    const [location, setLocation] = useLocation();

    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated()) {
        return (<></>);
    } else {
        setLocation('/login');
    }

    return (
        <></>);
}

export default Header;